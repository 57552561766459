import React, { Component } from 'react';

export default class MapContainer extends Component {
    render() {
        return (
            <div id="mapcontainer">
                <div id="map" className="map"></div>
            </div>
        );
    }
}
